$labelColor: rgb(60, 60, 67, 0.6);
$font: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI,
  Arial, sans-serif;

.marketSpec {
  width: 600px;

  &__selectedBtn {
    background-color: black;
    border-radius: 10px;
    &:hover {
      background-color: black;
      color: white;
    }
  }

  &__unselectedBtn {
    background-color: white;
    &:hover {
      background-color: red;
      color: white;
    }
    &:focus {
      background-color: green;
      color: white;
    }
    &:active {
      background-color: yellow;
      color: white;
    }
    border-radius: 10px;
  }
}

.addCartBtn {
  font-family: $font;
  font-size: 1.8rem;
  font-weight: bolder;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.checkoutBtn {
  font-family: $font;
  font-size: 1rem;
  font-weight: regular;
}

.titleBtnLine {
  height: 4px;
  background: black;
  width: 100%;
}

.marketTitle {
  display: flex;
  font-family: $font;
  font-size: 1.5rem;
  font-weight: bolder;
}

.marketStatus {
  display: flex;
  font-family: $font;
  font-size: 1.5rem;
  font-weight: bold;
}

.statusBtn {
  font-family: $font;
  font-size: 1.2rem;
  font-weight: bolder;
  max-height: 3rem;
}

.contactName {
  font-family: $font;
  font-size: 1.2rem;
  font-weight: bold;
}

.marketPrice {
  font-family: $font;
  font-size: 2rem;
  font-weight: bolder;
}

.specPrice {
  font-family: $font;
  font-size: 1.5rem;
  color: red;
  font-weight: bolder;
}

.marketSpecTitle {
  font-family: $font;
  font-size: 1rem;
  font-weight: bolder;
  margin-right: 0.5rem;
  align-self: center;
}

.shipCard {
  align-self: flex-start;
  padding-left: 0;
  margin-left: 0;
}

.marketDelivery {
  font-family: $font;
  font-size: 0.9rem;
  font-weight: bold;
  margin-left: 1rem;
  color: black;
  &__truck {
    color: black;
    font-size: 1.2rem;
    align-self: center;
  }
}

.marketDeliveryDetailsTitle {
  font-family: $font;
  font-weight: bolder;
  font-size: 1rem;
  color: black;
}

.marketDeliveryDetailsContent {
  font-family: $font;
  font-size: 0.9rem;
  font-weight: regular;
  color: black;
}

.marketCartBtn {
  align-self: center;
  margin-left: 5px;
}

.marketSpecDetails {
  font-family: $font;
  font-size: 0.8rem;
  font-weight: bold;
}

.inputControlBtn {
  color: black;
  &:hover {
    color: black;
  }
}

.cartDeleteBtn {
  align-self: center;
}

.cartSpecTitle {
  font-family: $font;
  font-size: 1.5rem;
  font-weight: bolder;
}

.cartSpecDesc {
  font-family: $font;
  font-size: 1rem;
  font-weight: regular;
  color: gray;
}

.cartSpecUnitPrice {
  font-family: $font;
  font-size: 1.2rem;
  font-weight: regular;
}

.cartSpecPrice {
  font-family: $font;
  font-size: 1.5rem;
  font-weight: bolder;
}

.cartSpecTotPrice {
  font-family: $font;
  font-size: 1.5rem;
  font-weight: bolder;
}

.cartSpecQuanChkout {
  white-space: pre-wrap;
  font-family: $font;
  font-size: 1.2rem;
  font-weight: regular;
}

.orderOOSAlert {
  white-space: pre-wrap;
  font-family: $font;
  font-size: 1.2rem;
  font-weight: regular;
}

.receiptLink {
  white-space: pre-line;
  word-break: break-word;
  word-wrap: break-word;
  font-family: $font;
  font-size: 1.2rem;
  font-weight: bold;
  height: auto;
}

.selSpecPhoto {
  width: 40%;
  height: auto;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.25rem;
  border-color: black;
}

.cartContainer {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
  padding-left: -1rem;
}

.cartInputGroup {
  align-self: center;
}

.tmpBorder {
  border-style: solid;
  border-width: 0.25rem;
  border-color: black;
}

.cartContent {
  &__photo {
    width: 20%;
    max-width: 8rem;
    min-width: 3rem;
    height: auto;
  }
}

.contactMethodTitle {
  font-family: $font;
  font-size: 1.2rem;
  font-weight: bold;
}

.emptyCart {
  align-content: center;
  justify-content: center;

  &__title {
    font-family: $font;
    font-size: 2rem;
    font-weight: bolder;
    color: $labelColor;
  }

  &__btn {
    font-family: $font;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.mktOrder {
  &__orderTimestamp {
    font-family: $font;
    font-size: 1.2rem;
    font-weight: regular;
    color: rgb(142, 142, 147);
  }

  &__orderTitle {
    font-family: $font;
    font-size: 1.2rem;
    font-weight: regular;
    color: black;
  }
}

/*Slick Carousel (not working)*/

.sliderWrapper {
  width: 760px;
  margin: auto;
}
.slickSlide {
  text-align: center;
  position: relative;
}
.slickSlide:focus {
  outline: none;
}
.slickSlideTitle {
  text-transform: capitalize;
}
.slickSlideImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
.slickSlideLabel {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slickPrev:before,
.slickNext:before {
  color: #777777;
}

.thumbnailSliderWrap {
  margin-top: 15px;
  height: 85px;
}
.thumbnailSliderWrap .slickTrack .slickSlide {
  text-align: center;
}
.thumbnailSliderWrap .slickTrack .slickSlide img {
  width: 90%;
}
