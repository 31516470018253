@import "~bootstrap/scss/bootstrap";
$font: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial,
  sans-serif;
$height: 30rem;
/* Mobile Styles */
@media only screen and (max-width: 400px) {
  #mainContainer {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 400px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  $height: 20rem;

  ol,
  li {
    margin-left: 5px;
    margin-bottom: 2px;
  }

  body {
    font-size: 14px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #mainContainer {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 960px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  ol,
  li {
    margin-left: 15px;
    margin-bottom: 4px;
  }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  #mainContainer {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 1500px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  ol,
  li {
    margin-left: 30px;
    margin-bottom: 14px;
  }
}

.superContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

.roundedCorners {
  border-radius: 20px;
}

.roundedCorners10 {
  border-radius: 10px;
}

.marketImg {
  border-radius: 20px 20px 0 0px;
  height: $height * 0.4;
  object-fit: contain;
  background-color: #efeff4;
}

.marketDescText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marketDescText2 {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marketPriceText {
  margin-bottom: 0;
}

.label {
  margin-right: 5px;
}

.marketPhoto {
  height: 400px;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.card-container {
  box-shadow: 4px 12px 30px 6px rgba(0, 0, 0, 9%); /* Card look */
  /*border-color: rgba(211, 211, 211, 1);*/
  border-width: 0;
  /*min-width: 10rem;
  max-width: $height * 0.8;*/
  overflow: hidden;
  height: auto;
}

.card-container:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

.bg-whiteShadow {
  box-shadow: 0px 3px 10px -3px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.btn-nav {
  font-family: $font;
  font-size: 1rem;
  font-weight: bolder;
  align-items: center;
  background: #fff;
  color: red;
  border: 2px solid red;
  border-radius: 3px;
  padding: 5px 10px;
}

.topicHeader {
  display: flex;
  height: auto;
  width: 100%;
  overflow: hidden;
  top: 0;
  padding-bottom: 20px;
  justify-content: center;
}

.topicHeaderImg {
  width: 100%;
  max-width: 1500px;
  height: auto;
  object-fit: contain;
}

.avatarImg {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.fileDetails {
  font-size: 12px;
}

.fileTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  margin-right: 10px;
}

.fileContainer {
  position: relative;
  display: inline-block;
  max-width: 70%;
  background-color: #efeff4;
  color: #666666;
  border-radius: 10px;
}

i {
  display: inline-block;
  align-self: center;
  font-size: 40px;
  margin: 10px;
}

.file {
  display: flex;
  flex-flow: row;
  align-content: center;
  width: auto;
  height: auto;

  color: #666666;
  text-decoration: none;
}

.videoContainer {
  position: relative;
  display: block;
  height: 0;
  padding-top: 56.25%; /*16:9*/
  box-sizing: border-box;
  background-color: black;
  max-width: 100%;
  min-width: 300px;
  width: stretch;
  overflow: hidden;
  align-content: center;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.videoOverlay {
  opacity: 0;
}

code {
  font-family: $font, monospace;
}

blockquote {
  font: 14px/22px normal HelveticaNeue, sans-serif;
  background-color: #f1f1f1;
  border-left: 3px solid #007aff;
  margin: 1.5em 5px;
  padding: 0.5em 4px;
}

.avatar-image {
  position: absolute;
  left: 20px;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: visible;
  line-height: normal;
  position: relative;
  height: 60px;
  width: auto;
}

.avatar-name {
  position: absolute;
  top: 5px;
  left: 80px;
  display: inline-block;
  width: 200px;
  height: 25px;
  padding-left: 15px;
  padding-bottom: 3px;
  font-family: $font, sans-serif !important;
  letter-spacing: -0.02em !important;
  font-weight: 400 !important;
  font-style: normal !important;
  letter-spacing: 0 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 1) !important;
}

.avatar-timestamp {
  position: absolute;
  bottom: 5px;
  left: 80px;
  display: block;
  width: 200px;
  height: 25px;
  padding-left: 15px;
  padding-top: 3px;
  font-family: $font;
  letter-spacing: -0.02em !important;
  font-weight: 400 !important;
  font-style: normal !important;
  letter-spacing: 0 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: rgba(73, 80, 87, 1) !important;
}

.title {
  position: relative;
  width: 100%;
  height: auto;
}

.title-font {
  display: block;
  text-align: center;
  font-family: $font;
  font-style: bold;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: 0;
  max-width: 100%;
  min-width: 300px;
}

.homeTitle {
  font-family: $font;
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
}

.homeSubtitle {
  font-family: $font;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.homeSubsubtitle {
  font-family: $font;
  font-size: 1.5rem;
  font-weight: regular;
  text-align: center;
  color: rgba(73, 80, 87, 1);
}

img {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  /*min-width: 300px;*/
  width: 100%;
  height: auto;
  vertical-align: middle;
}
figure {
  display: block;
  margin-top: 0em;
  margin-bottom: 0em;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  min-width: 300px;
  width: stretch;
  height: auto;
  background-color: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

figcaption {
  text-decoration: none;
  background-color: #eeeeee;
  color: #676767;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  font: 13px normal HelveticaNeue, sans-serif;
  font-weight: bold;
}

a {
  text-decoration: none;
}

h1 {
  font-family: $font;
  font-size: 40px;
  font-weight: bold;
}

h2 {
  font-family: $font;
  font-size: 30px;
  font-weight: bold;
}

h3 {
  font-family: $font;
  font-size: 20px;
  font-weight: bold;
}

h4 {
  font-family: $font;
  font-size: 18px;
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;

  font-family: $font;
  font-size: 16px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-family: $font;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.003em;
}

/*Slick Carousel*/

.slider-wrapper {
  width: 760px;
  margin: auto;
}
.slick-slide {
  text-align: center;
  position: relative;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: capitalize;
}
.slick-slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: #777777;
}

//TODO: border is not showing
.slick-slide {
  &:focus,
  a {
    outline: black;
    outline-width: 0.5rem;
    outline-style: solid;
    outline-color: black;
    overflow: hidden;
  }
}

.thumbnail-slider-wrap {
  margin-top: 15px;
  height: auto;
  background-color: white;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 90%;
}

//Login
.login {
  max-width: 30rem;

  &__submitBtn {
    width: 100%;
    font-family: $font;
    font-size: 1.2rem;
    font-weight: bolder;
  }

  &__title {
    font-family: $font;
    font-size: 2rem;
    font-weight: bolder;
  }

  &__subtitle {
    font-family: $font;
    font-size: 1.5rem;
    font-weight: bolder;
  }
}

.loginContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  color: black;
  justify-content: center;
  align-items: left;
  flex-direction: column;
}

.loginText {
  white-space: pre-line;
  font-family: $font;
  font-size: 1rem;
  font-weight: regular;
}

.firebaseUi {
  min-width: 250px;
}

.numberInput {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.homeSignupBtn {
  font-family: $font;
  font-size: 1.5rem;
  font-weight: bolder;
}

.homeEmail {
  font-family: $font;
  font-size: 1.2rem;
  font-weight: regular;
}

//404
.notFound {
  &__title {
    font-family: $font;
    font-size: 2rem;
    font-weight: bolder;
  }

  &__body {
    font-family: $font;
    font-size: 1.5rem;
    font-weight: regular;
  }

  &__link {
    font-family: $font;
    font-size: 2rem;
    font-weight: bold;
  }
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.spinner {
  &__box {
    background-color: white;
  }

  &__loading {
    font-family: $font;
    font-size: 1.5rem;
    font-weight: bolder;
    color: #676767;
  }
}

.tmpBorder {
  border-width: 0.5;
  border-color: black;
  border-style: solid;
}

.tmpRedBorder {
  border-width: 0.5;
  border-color: red;
  border-style: solid;
}

.noScrollX {
  overflow-x: hidden;
  width: 100vw;
}
